import React from 'react'

function Legal() {
  return (
    <div className="container">
      <article className="page-article">
        <h1>Terms and conditions</h1>
        <p className="lead">
          Welcome to our website. If you continue to browse and use this
          website, you are agreeing to comply with and be bound by the following
          terms and conditions of use, which together with our privacy policy,
          govern the relationship between you, Zaven sp. z o.o. and this
          website. If you disagree with any part of these terms and conditions,
          please do not use our website.
        </p>
        <p>
          The term ‘Zaven sp. z o.o.’ or ‘us’ or ‘we’ refers to the owner of the
          website, registered under the following identification:{' '}
          <em>
            Zaven Sp. z o.o., ul. Sienna 5B, 51-349 Wrocław (Poland), VAT-ID
            PL8952031503, KRS 0000515347, Sąd Rejonowy dla Wrocławia-Fabrycznej,
            IV Wydział Gospodarczy KRS, kap. zakł. 40050 zł.
          </em>{' '}
          The term ‘you’ refers to the user or viewer of our website.
        </p>
        <h2>Terms of use</h2>
        <ol>
          <li>
            The content of the pages of this website is for your general
            information and use only. It is subject to change without notice.
          </li>
          <li>
            This website uses cookies to monitor browsing preferences, as
            described in our Privacy Policy.
          </li>
          <li>
            Neither we nor any third parties provide any warranty or guarantee
            as to the accuracy, timeliness, performance, completeness or
            suitability of the information and materials found or offered on
            this website for any particular purpose. You acknowledge that such
            information and materials may contain inaccuracies or errors and we
            expressly exclude liability for any such inaccuracies or errors to
            the fullest extent permitted by law.
          </li>
          <li>
            Your use of any information or materials on this website is entirely
            at your own risk, for which we shall not be liable. It shall be your
            own responsibility to ensure that any products, services or
            information available through this website meet your specific
            requirements.
          </li>
          <li>
            This website contains material which is owned by or licensed to us.
            This material includes, but is not limited to, the design, layout,
            look, appearance and graphics. Reproduction is prohibited other than
            in accordance with the copyright notice, which forms part of these
            terms and conditions.
          </li>
          <li>
            All trademarks reproduced in this website, which are not the
            property of, or licensed to the operator, are acknowledged on the
            website.
          </li>
          <li>
            Unauthorised use of this website may give rise to a claim for
            damages and/or be a criminal offence.
          </li>
          <li>
            From time to time, this website may also include links to other
            websites. These links are provided for your convenience to provide
            further information. They do not signify that we endorse the
            website(s). We have no responsibility for the content of the linked
            website(s).
          </li>
          <li>
            Your use of this website and any dispute arising out of such use of
            the website is subject to Polish law.
          </li>
        </ol>
        <hr id="privacy-policy" style={{ margin: '60px 0' }} />
        <h2>Privacy Policy</h2>
        <p className="lead">
          This privacy policy sets out how Zaven sp. z o.o. uses and protects
          any information that you give when you using this website.
        </p>
        <p>
          In accordance with the newly adopted Regulation 2016/679 on the
          protection of personal data, known as GDPR, we are informing you of
          our updated privacy policy which is in effect as of 25 May, 2018. We
          hope that you find our privacy policy to be clear, logical and
          transparent in regards to how we care about the privacy and safety of
          our users as well as to what ends we collect data. This is also an
          easy way to report any changes and updates, or to request the deletion
          of your data. You are welcome to submit and commits or queries to the
          following email address:{' '}
          <a href="mailto:gdpr@zaven.co">gdpr@zaven.co</a>.
        </p>
        <h3>Administrator of personal data</h3>
        <p>
          The administrator of your personal data is the Zaven Sp. z o.o., ul.
          Sienna 5b, 51-349 Wrocław, Poland.
        </p>
        <h3>Scope of this privacy policy</h3>
        <p>
          This privacy policy applies in particular to: visitors to our Internet
          website, people submitting applications for employment, blog readers,
          people using forms and content readers.
        </p>
        <h3>What is the purpose of our data collection?</h3>
        <p>Data on the website are collected in these areas:</p>
        <ul>
          <li>
            data regarding users of the website, processed for internal
            marketing,
          </li>
          <li>
            data regarding users of the website, collected for the purpose of
            providing services,
          </li>
          <li>data collected for recruitment,</li>
          <li>data collected for public legal purposes.</li>
        </ul>
        <h3>What data do we collect?</h3>
        <p>Zaven sp. z o.o. collects the following information:</p>
        <ul>
          <li>email address,</li>
          <li>telephone number,</li>
          <li>other data entered into message fields.</li>
        </ul>
        <h3>What data is collected through third-party applications?</h3>
        <ul>
          <li>
            anonymous usage statistics (e.g. behavior on the website, time
            spent, browser, language),
          </li>
          <li>
            device specific information (e.g your hardware model, operation
            system version),
          </li>
          <li>
            log information (e.g how you used our web, internet protocol
            address, referral url),
          </li>
          <li>
            information about services that you use and how you use them, e.g.
            when you visit a website,
          </li>
          <li>location information.</li>
        </ul>
        <p>
          Non-personal data are collected temporarily for statistical purposes
          and to improve the site for a better user experience. Zaven’s website
          collects the least amount of data possible, only for the fulfillment
          of selected objectives, maintaining such practices as privacy by
          design, privacy by default and data minimization.
        </p>
        <h3>User statement</h3>
        <p>
          The user represents that they have read the privacy policy and have
          taken all necessary measures to ensure the security of their system
          and their browser, reviewing the website's certificate and its policy
          for the use of cookies. A user submitting an email address, declares
          that it is their own.
        </p>
        <h3>Security</h3>
        <p>
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorised access or disclosure, we have put suitable
          physical, electronic and managerial procedures in place to safeguard
          and secure the information we collect online.
        </p>
        <h3>How We Use Cookies</h3>
        <p>
          A cookie is a small file that asks permission to be placed on your
          computer’s hard drive. Once you agree, the file is added and the
          cookie helps analyse web traffic or lets you know when you visit a
          particular site. Cookies allow web applications to respond to you as
          an individual. The web application can tailor its operations to your
          needs, likes and dislikes by gathering and remembering information
          about your preferences.
        </p>
        <p>
          We use traffic log cookies to identify which pages are being used.
          This helps us analyse data about web page traffic and improve our
          website in order to tailor it to customer needs. We only use this
          information for statistical analysis purposes and then the data is
          removed from the system.
        </p>
        <p>
          Overall, cookies help us provide you with a better website, by
          enabling us to monitor which pages you find useful and which you do
          not. A cookie in no way gives us access to your computer or any
          information about you, other than the data you choose to share with
          us.
        </p>
        <p>
          You can choose to accept or decline cookies. Most web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. This may prevent you from
          taking full advantage of the website.
        </p>
        <h3>User rights</h3>
        <p>
          We believe in minimizing data processing and respecting user data,
          therefore, in regards to the GDPR regulation, in this case Article 7,
          we are informing the user of the data processing policy on the Zaven
          website and the user right to manage their data, in particular:
        </p>
        <ul>
          <li>
            contact if you have any questions about the security of our website
            at the dedicated e-mail address:{' '}
            <a href="mailto:gdpr@zaven.co">gdpr@zaven.co</a>,
          </li>
          <li>
            the right of the user to not process their data for longer than to
            achieve the purposes of the processing,
          </li>
          <li>
            the right to ask for the personal data processed by Zaven about
            themselves,
          </li>
          <li>
            the right to change, correct, delete one’s own personal data from
            the data base at any time and without consequence by contacting
            Zaven at the address:{' '}
            <a href="mailto:gdpr@zaven.co">gdpr@zaven.co</a>,
          </li>
          <li>
            the right to withdraw from the agreement to receive an email
            newsletter, by clicking on unsubscribe within the news email or at
            the address: <a href="mailto:gdpr@zaven.co">gdpr@zaven.co</a>,
          </li>
          <li>
            the right to the total deletion of their own data, as well as data
            in the third-party applications by contacting Zaven at the address:{' '}
            <a href="mailto:gdpr@zaven.co">gdpr@zaven.co</a>,
          </li>
          <li>
            user rights are a priority to use, therefore any claims will be
            executed within 30 days of the original date of notification.
          </li>
        </ul>
        <h3>
          Tools used on the Zaven website, in accordance with article 6 of GDPR
        </h3>
        <ul>
          <li>
            Hotjar - a tool used for anonymous tracking of the user on the
            website, including users’ clicks, page scrolling, location, session
            length and operating system.
          </li>
          <li>
            Google Analytics - an analytical tool that collects anonymous data
            for statistical purposes and improving the site. The data is
            processed for 26 months. The data is kept on Google servers in the
            USA.
          </li>
        </ul>
        <p>
          The Zaven website does not have tags for the purpose of
          personalization nor for advertisement targeting. We respect the user’s
          right to the transparent use of available data and do not use data
          regarding the user for targeted advertising not do we transmit such
          data to external advertising data bases.
        </p>
        <p>
          You can disable these tools by installing the Adblock plugin in your
          web browser.
        </p>
        <h3>Use od supplied data</h3>
        <p>
          Zaven uses the data supplied in data forms for return communication,
          such as:
        </p>
        <ul>
          <li>a reply to a query through a contact form,</li>
          <li>a reply to a recruitment request,</li>
          <li>the delivery of our newsletter,</li>
          <li>the delivery of our infopack.</li>
        </ul>
        <p>
          The newsletter requires two levels of verification in giving consent
          to receive the presented information regarding the Zaven’s activities
          via email. The user may cancel their subscription at any time by
          clicking the unsubscribe button in the email. Zaven uses an external
          service, MailChimp, to carry out the newsletter service, to which
          Zaven entrusts anonymous data.
        </p>
        <p>
          Recruitment and HR activities are based on the receipt of a CV or
          resume at an email address. Access to candidates’ application CVs or
          resumes is available only to HR, managerial and personnel who are
          familiar with the protection rules regarding this data. From 25 May
          2018, all applications processed by Zaven must include a data
          processing clause in accordance with Article 6 paragraph 1 of GDPR,
          otherwise, these applications will immediately be removed from the
          database. Recruitment data is stored for 3 years.
        </p>
        <h3>Use by minors</h3>
        <p>
          Zaven website does not knowingly collect data from users under the age
          of 13. Services offered by Zaven are business services and concern
          adults, therefore youths under 18 years of age should browse the site
          with the permission of or in the presence of a parent or guardian.
        </p>
        <h3>Links to Other Websites</h3>
        <p>
          Our website may contain links to other websites of interest. However,
          once you have used these links to leave our site, you should note that
          we do not have any control over that other website. Therefore, we
          cannot be responsible for the protection and privacy of any
          information that you provide whilst visiting such sites and such sites
          are not governed by this privacy statement.
        </p>
        <p>
          You should exercise caution and look at the privacy statement
          applicable to the website in question.
        </p>
        <h3>Changes in Privacy Policy</h3>
        <p>
          We reserve the right to make changes to this privacy policy in
          connection with changes of law, provision of services or changing
          technology. We recommend visiting the website to stay up-to-date with
          the changes.
        </p>
        <p>
          In case of ambiguity regarding the above privacy policy or any
          questions regarding the user's safety, please contact us via email at{' '}
          <a href="mailto:gdpr@zaven.co">gdpr@zaven.co</a>.
        </p>
      </article>
    </div>
  )
}

export default Legal
