import React from 'react'
import NavBar from '../components/common/NavBar'
import Legal from '../components/legal/Legal'

const Index = () => {
  return (
    <>
      <NavBar />
      <Legal />
    </>
  )
}

export default Index
